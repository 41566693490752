<template>
    <div class="bg center_xy">

        <div class=" border_box center_xy" style="z-index: 100">
            <div class="border_run_left center_xy" style="z-index: 501">
                <square_cir id="canvan_left" width_in="644" height_in="592" radius_in="600"
                            style="position: absolute;left: 4px  !important;"></square_cir>
            </div>

            <div class="content_box_left center_y" style="z-index: 502">
                <div class="center_xy content_text content_bg_left" @click="content_popup_open(0)">
                    信息共享体系
                    <!--                    <img src="~@/assets/image/titile_bg.png">-->
                </div>
                <div class="center_xy content_text content_bg_left" @click="content_popup_open(1)">
                    智慧物流体系
                </div>
                <div class="center_xy content_text content_bg_left" @click="content_popup_open(2)">
                    金融服务体系
                </div>
                <div class="center_xy content_text content_bg_left" @click="content_popup_open(3)">
                    信用保障体系
                </div>
            </div>

            <div class="border_run_right center_xy">
                <square_cir id="canvan_right" width_in="644" height_in="592" radius_in="600" :around_left="false"
                            style="position: absolute;right: 4px  !important;"></square_cir>
            </div>
            <div class="content_box_right center_y">
                <div class="center_xy content_text content_bg_right" @click="content_popup_open(4)">
                    统计监测体系
                </div>
                <div class="center_xy content_text content_bg_right" @click="content_popup_open(5)">
                    风险防范体系
                </div>
                <div class="center_xy content_text content_bg_right" @click="content_popup_open(6)">
                    人才支撑体系
                </div>
            </div>

        </div>

        <div class="title_box" style="z-index: 600">
            <!--            <img class="title_bg" src="~@/assets/image/title.png">-->
            <!--            <img class="title_bg" src="~@/assets/image/name_bg.png">-->
            <div class="center_y" style="position:absolute;z-index: 601;width:100%;margin-top:20px">
                <div class="title_text">跨境电商线上综合公共服务平台</div>
                <!--                <div class="title_text"></div>-->
            </div>
        </div>

        <div class="map center_xy" style="z-index: 400">
            <div class="map_d ">
                <img class="map1" src="~@/assets/image/lbx.png">
                <img class="map2" src="~@/assets/image/jt.png">
                <img class="map3" src="~@/assets/image/diqiu.png">
            </div>
        </div>

        <div class="business_box center_xy" style="z-index: 300">
            <div class="business_border"></div>
            <div class="business_ball center_xy" style="position:absolute;left:calc(50% - 75px);top:-50px">
                <business_com :animation_flg="false"
                              :img_src="require('@/assets/svg/jinrongye.svg')"
                              title="金融机构"></business_com>
            </div>

            <div class="business_ball center_xy" style="position:absolute;left:-20px;top:290px">
                <business_com :img_src="require('@/assets/svg/wuliu.svg')"
                              :animation_flg="false"
                              title="物流企业"></business_com>
            </div>

            <div class="business_ball center_xy" style="position:absolute;right:-20px;top:290px">

                <business_com :animation_flg="false"
                              :img_src="require('@/assets/svg/baoguandanguanli.svg')"
                              title="报关企业"></business_com>

            </div>

            <div class="business_ball center_xy" style="position:absolute;left:120px;bottom:40px">
                <business_com :animation_flg="false"
                              :img_src="require('@/assets/svg/dianshang.svg')"
                              title="电商企业"></business_com>
            </div>

            <div class="business_ball center_xy" style="position:absolute;right:120px;bottom:40px">
                <business_com :animation_flg="false"
                              :img_src="require('@/assets/svg/pinpaixiaofeizhe.svg')" title="消费者"></business_com>

            </div>
        </div>

        <div class="box" style="z-index: 500">
            <div class="ball " style="left:1000px;top:-50px">
                <business_com :animation_flg="false" :img_src="require('@/assets/svg/icon-ai-rpa3.svg')"
                              title="海关"></business_com>

            </div>
            <div class="ball " style="left: 300px;top:210px">
                <business_com :animation_flg="false" :img_src="require('@/assets/svg/shuiwu.svg')"
                              title="税务"></business_com>
            </div>
            <div class="ball " style="right: 300px;top:210px">
                <business_com :animation_flg="false" :img_src="require('@/assets/svg/DVLINK_waiguanju.svg')"
                              title="外管"></business_com>
            </div>
            <div class="ball " style="left:-60px ; top:1000px">
                <business_com :animation_flg="false" :img_src="require('@/assets/svg/shangwu.svg')"
                              title="商务"></business_com>
            </div>
            <div class="ball " style="right:-150px ; top:1000px">
                <business_com :animation_flg="false" :img_src="require('@/assets/svg/guojimaoyifa2.svg')"
                              title="国际贸易”单一窗口“"></business_com>
            </div>
            <div class="ball " style="left:300px ; top:1810px">
                <business_com :animation_flg="false" :img_src="require('@/assets/svg/kouan.svg')"
                              title="电子口岸"></business_com>
            </div>
            <div class="ball " style="right:280px ; top:1810px">
                <business_com :animation_flg="false" :img_src="require('@/assets/svg/zhengfulei.svg')"
                              title="其他政府部门"></business_com>
            </div>
        </div>

        <div class="md-modal md_effect_left" id="modal_popup_left">
            <div class="md-content">
                <Business_popup id="business_popup_left" height_in="600" ref="shade_popup_left_func"></Business_popup>
            </div>
        </div>

        <div class="md-modal md_effect_right" id="modal_popup_right">
            <div class="md-content">
                <Business_popup id="business_popup_right" height_in="600" ref="shade_popup_right_func"></Business_popup>
            </div>
        </div>

        <!--        <Business_popup width_in="1000" style="position: absolute"></Business_popup>-->

        <div id="shade_popup" @click="content_popup_close" class="md_hidden content_popup_box"></div>

    </div>

</template>

<script>
    import business_com from "../components/business_com"
    import square_cir from "../components/square_cir"
    import Business_popup from "../components/business_popup";

    var data_content = [
        {
            title: '信息共享体系',
            content: '多位一体的跨境电商信息合作机制和共享平台，统一信息标准规范、信息备案认证、信息管理服务，实现外管、海关、税务部门、服务机构、企业间信息互联互通，为跨境电商信息流、资金流、物流“三流合一”提供数据技术支撑。'
        },
        {
            title: '智慧物流体系',
            content: '择优引进一批大型物流企业，如：中外运、顺丰、邮政、DHL、Fedex及中通、圆通等物流企业，鼓励应用智能技术和装备，共同为跨境电商提供智能化物流服务。鼓励平台以“互联网＋供应链”模式提供一站式综合物流服务。'
        },
        {
            title: '金融服务体系',
            content: '引进银行等金融机构入驻平台，如：北京银行、建设银行、中信保及跨境支付机构等，与跨境电商综合服务企业合作，以平台真实业务数据为支撑，建立企业信用体系，为跨境电商交易提供在线融资、保险服务、支付对接等一站式金融服务。'
        },
        {title: '信用保障体系', content: '开展事前事中事后协同监管，收集跨境电商各类企业信用数据，提供信用记录或信用综合评价查询服务，实现信息分类监管、部门共享、有序公开。'},
        {
            title: '统计监测体系',
            content: '实现跨境电商数据的采集、统计、分析和共享，提高数据整合分析能力。探索跨境电商B2B出口统计方法。开展以样本抽取、企业直报、企业调查为主的统计试点工作，探索制订跨境电商统计标准。'
        },
        {title: '风险防范体系', content: '建立风险信息采集、评估分析、预警处置机制，有效防控非真实贸易洗钱的经济风险，以及数据存储、支付交易、网络安全的技术风险和产品安全、贸易摩擦、主体信用的交易风险。'},
        {
            title: '人才支撑体系',
            content: '推动形成政府、高等学校、企业和协会四位一体的跨境电商人才培训机制。实施跨境电商人才引进计划,按照石家庄市人才绿卡有关规定落实优惠政策，建立人才需求、招聘、培训、交流等线上咨询交流平台。'
        },
    ];

    export default {
        name: "business",
        components: {
            Business_popup,
            square_cir,
            business_com
        },

        methods: {
            content_popup_open: function (type) {

                this.content_popup_close()
                if (type < 4) {
                    var modal_popup_left_div = document.getElementById('modal_popup_left');
                    modal_popup_left_div.classList.add("md_show_left")
                    this.$refs.shade_popup_left_func.setText(data_content[type].title, data_content[type].content);

                } else {
                    var modal_popup_right_div = document.getElementById('modal_popup_right');
                    modal_popup_right_div.classList.add("md_show_right")
                    this.$refs.shade_popup_right_func.setText(data_content[type].title, data_content[type].content);
                }


                var shade_popup_div = document.getElementById('shade_popup');
                shade_popup_div.classList.add("md_show_left")
                shade_popup_div.classList.remove("md_hidden")
            },

            content_popup_close: function () {

                var modal_popup_left_div = document.getElementById('modal_popup_left');
                modal_popup_left_div.classList.remove("md_show_left")

                var modal_popup_right_div = document.getElementById('modal_popup_right');
                modal_popup_right_div.classList.remove("md_show_right")

                var shade_popup_div = document.getElementById('shade_popup');
                shade_popup_div.classList.remove("md_show_left")
                shade_popup_div.classList.add("md_hidden")

            }
        }
    }

</script>

<style scoped>
    * {
        margin: 0;
        padding: 0;
    }

    .bg {
        height: 100%;
        width: 100%;
    }

    .center_xy {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .center_y {
        display: flex;
        align-content: space-between;
        flex-wrap: wrap;
    }


    @keyframes myfirst2 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }

    @keyframes business_box {
        from {
            transform: rotate(0deg);
            transform-origin: center center;
        }
        to {
            transform: rotate(-359deg);
            transform-origin: center center;
        }
    }

    @keyframes business_ball {
        from {
            transform: rotate(0deg);
            transform-origin: center 50px;
        }
        to {
            transform: rotate(359deg);
            transform-origin: center 50px;
        }
    }

    .map {
        position: absolute;
        height: 1000px;
        width: 1000px;
    }

    .map_d {
        width: 900px;
        height: 900px;
    }

    .map1, .map2, .map3 {
        position: absolute;
        opacity: .5
    }

    .map1 {
        width: 900px;
        animation: myfirst2 15s infinite linear;
    }

    .map2 {
        width: 900px;
        opacity: 0.5;
        animation: business_box 10s infinite linear;
    }

    .map3 {
        width: 800px;
        margin-left: 50px;
        margin-top: 50px;
    }

    .business_box {
        width: 1200px;
        height: 1200px;
        position: absolute;
        animation: business_box 70s infinite linear;
    }

    .business_ball {
        animation: business_ball 70s infinite linear;
    }

    .business_border {
        position: absolute;
        width: 1200px;
        height: 1200px;
        opacity: 1;
        border-radius: 50%;
        border: 2px dashed #16f4ff;
        box-shadow: rgb(11, 234, 235) 0px 0px 10px;
    }

    .border_box {
        width: 2500px;
        height: 600px;
        position: absolute;
    }

    .title_box {
        width: 850px;
        position: absolute;
        height: 120px;
        z-index: 1000;
        padding: 10px;
        background: url("../assets/image/new_title_bg.png") no-repeat;
        background-size: 100% 100%;
    }

    .title_bg {
        width: 100%;
        height: 180px;
        position: absolute;
    }

    .title_text {
        text-align: center;
        font-size: 47px;
        color: white;
        font-weight: bold;
        width: 100%;
        padding: 20px;
        letter-spacing: 6px
    }

    .content_box_left {
        position: absolute;
        width: 300px;
        height: 80%;
        left: -100px
    }

    .content_box_right {
        position: absolute;
        width: 300px;
        height: 80%;
        right: -100px
    }

    .content_text {
        font-size: 33px;
        font-weight: bold;
        color: white
    }

    .content_bg_left {
        width: 100%;
        height: 20%;
        /*-moz-box-shadow: 2px 2px 4px #1adcff;*/
        /*-webkit-box-shadow: 2px 2px 4px #1adcff;*/
        /*box-shadow:2px 2px 4px #1adcff;*/
        background: url("../assets/image/titile_bg.png") no-repeat;
        background-size: 100% 100%;
    }

    .content_bg_right {
        /*background-color: #257dff;*/
        width: 100%;
        height: 22%;
        /*-moz-box-shadow: 2px 2px 4px #1adcff;*/
        /*-webkit-box-shadow: 2px 2px 4px #1adcff;*/
        /*box-shadow:2px 2px 4px #1adcff;*/
        /*background: url("../assets/image/titile_bg2.png") no-repeat;*/
        background: url("../assets/image/titile_bg.png") no-repeat;
        background-size: 100% 100%;
    }

    .border_run_left {
        width: 650px;
        height: 100%;
        left: 0;
        position: absolute;
        background-color: transparent;
        background: transparent;
        background: linear-gradient(0deg, transparent 50%, #1adcff 50%) repeat-y,
        linear-gradient(0deg, transparent 50%, transparent 50%) repeat-y,
        linear-gradient(90deg, transparent 50%, #1adcff 50%) repeat-x,
        linear-gradient(90deg, transparent 50%, #1adcff 50%) repeat-x;
        border-radius: 4px;
        background-size: 4px 16px, 4px 16px, 16px 4px, 16px 4px;

        background-position: 0 0, 100% 0, 0 0, 0 100%;
        animation: shine 1s infinite linear;
    }

    .border_run_right {
        width: 650px;
        height: 100%;
        right: 0;
        position: absolute;
        background-color: transparent;
        background: transparent;
        background: linear-gradient(0deg, transparent 50%, transparent 50%) repeat-y,
        linear-gradient(0deg, transparent 50%, #1adcff 50%) repeat-y,
        linear-gradient(90deg, transparent 50%, #1adcff 50%) repeat-x,
        linear-gradient(90deg, transparent 50%, #1adcff 50%) repeat-x;
        border-radius: 4px;
        background-size: 4px 16px, 4px 16px, 16px 4px, 16px 4px;

        background-position: 0 0, 100% 0, 0 0, 0 100%;
        animation: shine 1s infinite linear;
    }

    @keyframes shine {
        from {
        }
        to {
            background-position: 0 -12px, 100% 12px, 12px 0, -12px 100%;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(-30deg) scaleY(.2) rotate(0deg);
        }
        100% {
            transform: rotate(-30deg) scaleY(.2) rotate(360deg);
        }
    }

    @keyframes anti-spin {
        0% {
            transform: rotate(0deg) scaleY(5) rotate(30deg) scale(1);
        }
        100% {
            transform: rotate(-360deg) scaleY(5) rotate(30deg) scale(1);
        }
    }

    .box {
        position: relative;
        width: 2100px;
        height: 2100px;
        border-radius: 50%;
        border: 8px dashed #16f4ff;
        box-shadow: rgba(11, 234, 235, 0.5) 0px 0px 10px;

        animation: spin 70s infinite linear;
    }

    .box .ball {
        display: inline-block;
        border-radius: 50%;
        animation: anti-spin 70s infinite linear;
        position: absolute;
        text-align: center;
    }

    .md-modal {
        position: absolute;
        height: auto;
        z-index: 5000;
        visibility: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .md_effect.md-modal {
        /*-webkit-perspective: 1300px;*/
        /*-moz-perspective: 1300px;*/
        /*perspective: 1300px;*/
    }

    .md_effect_left .md-content {
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
        -moz-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
        -ms-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
        transform: translateZ(100px) translateX(-30%) rotateY(90deg);
        -webkit-transform-origin: 0 100%;
        -moz-transform-origin: 0 100%;
        transform-origin: 0 100%;
        opacity: 0;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
    }

    .md_show_left.md_effect_left .md-content {
        -webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
        -moz-transform: translateZ(0px) translateX(0%) rotateY(0deg);
        -ms-transform: translateZ(0px) translateX(0%) rotateY(0deg);
        transform: translateZ(0px) translateX(0%) rotateY(0deg);
        opacity: 1;
    }



    .md_effect_right .md-content {
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: translateZ(100px) translateX(110%) rotateY(-90deg);
        -moz-transform: translateZ(100px) translateX(110%) rotateY(-90deg);
        -ms-transform: translateZ(100px) translateX(110%) rotateY(-90deg);
        transform: translateZ(100px) translateX(110%) rotateY(-90deg);
        -webkit-transform-origin: 0 100%;
        -moz-transform-origin: 0 100%;
        transform-origin: 0 100%;
        opacity: 0;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
    }

    .md_show_right.md_effect_right .md-content {
        -webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
        -moz-transform: translateZ(0px) translateX(0%) rotateY(0deg);
        -ms-transform: translateZ(0px) translateX(0%) rotateY(0deg);
        transform: translateZ(0px) translateX(0%) rotateY(0deg);
        opacity: 1;
    }

    .md_show_right {
        visibility: visible;
    }

    .md_show_left {
        visibility: visible;
    }

    .md_hidden {
        visibility: hidden !important;
    }

    .md-content {
        color: #fff;
        /*background: yellow;*/
        position: relative;
        border-radius: 3px;
        margin: 0 auto;
    }

    .content_popup_box {
        height: 100%;
        width: 100%;
        background-color: #1296db;
        opacity: 0.1;
        position: absolute;
        z-index: 501;
    }

</style>
