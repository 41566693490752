<template>
    <div class="business1">
        <div class=" img_box center_xy">
            <div class="img_bg "></div>
            <img class="business_img_1" :src="img_src" v-if="img_src">
        </div>
        <div class="img_title">{{title}}</div>
    </div>
</template>

<script>
    export default {
        name: "business_com",

        props: {
            title: {type: String},
            img_src: {type: String},
        }
    }

</script>

<style scoped>

    .img_box {
        width: 100px;
        height: 100px;
        margin: 0 auto;
    }

    .img_bg {
        /*background-color: #1296db;*/
        opacity: 0.9;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        /*border: 3px white dashed;*/
        position: absolute;

        background: url("../assets/image/bussnies_img_bg.png") no-repeat;
        background-size:100% 100%;
    }

    .business_img_1 {
        width: 80px;
    }

    .img_title {
        margin-top: 20px;
        padding: 12px 25px;
        font-size: 26px;
        background-color: rgba(18, 150, 219 , 0.3);
        color: white;
        text-align: center;
        font-weight: bolder;
        opacity: 0.9;
        border: 1px #1296db solid;
        box-shadow: rgba(18, 150, 219 , 0.8) 0px 0px 10px ;
        /*background: url("../assets/image/img_content_bg.png") no-repeat;*/
        /*background-size:100% 100%;*/
    }

    .center_xy {
        display: flex;
        align-items: center;
        justify-content: center;
    }

</style>