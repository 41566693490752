<template>
    <canvas :id="id" :width="width_in" :height="height_in">
    </canvas>
</template>

<script>
    export default {
        name: "square_cir",

        props: {
            id: {type: String},
            radius_in: {type: String},
            width_in: {type: String},
            around_left: {
                type: Boolean,
                default: true
            },
            height_in: {type: String}
        },

        mounted() {

            var a = this.radius_in
            var b = this.height_in / 2
            var c = Math.sqrt((a * a) - (b * b))

            var can = document.getElementById(this.id);
            var cans = can.getContext('2d');
            cans.beginPath();

            if (this.around_left) {
                cans.arc(parseFloat(this.width_in) + parseFloat(c), this.height_in / 2, this.radius_in, 0.5 * Math.PI, 1.5 * Math.PI);
                cans.lineTo(0, 0)
                cans.lineTo(0, this.height_in)
                cans.lineWidth = 0;
                cans.strokeStyle = "rgba(18,150,219,0.3)";
                cans.fillStyle = "rgba(18,150,219,0.3)";

            } else {
                cans.arc(0 - parseFloat(c), this.height_in / 2, this.radius_in, 0.5 * Math.PI, 1.5 * Math.PI, 1);
                cans.lineTo(this.width_in, 0)
                cans.lineTo(this.width_in, this.width_in)
                cans.lineWidth = 0;
                cans.strokeStyle = "rgba(18,150,219,0.3)";
                cans.fillStyle = "rgba(18,150,219,0.3)";
            }

            cans.fill();
            cans.stroke();
            cans.closePath();
        }

    }
</script>

<style scoped>

</style>