<template>
    <div class="popup_box" :style="{height:height_in+ 'px',width:height_in*1.5656 +'px'}">
        <div class="title-box">{{title_text}}</div>
        <div class="content-box">{{content_text}}</div>
    </div>
</template>

<script>
    export default {
        name: "business_popup",
        props: {
            height_in: {type: String},
        },
        data() {
            return {
                title_text: "",
                content_text: ""
            };
        },
        methods: {
            setText: function (title_in, content_in) {
                this.title_text = title_in
                this.content_text = content_in
            }
        }
    }
</script>

<style scoped>

    .popup_box {
        background: url("../assets/image/business_popup.png") no-repeat;
        background-size: 100% 100%;
    }

    .title-box {
        height: 16%;
        display: flex;
        align-items: center;
        padding-left: 100px;
        color: white;
        font-size: 38px;
        font-weight: bolder;
    }

    .content-box {
        padding: 20px 100px;
        width: 100%;
        display: flex;
        align-items: center;
        color: white;
        font-size: 33px;
        font-weight: bolder;
        line-height: 70px;
    }

</style>